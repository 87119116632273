import { forwardRef, useRef, useState } from 'react'
import type { ComponentPropsWithRef, ReactNode, FormEvent } from 'react'
import { Form, Input, Label, Link, LoadingButton } from '@faststore/ui'
import { NewsletterIcon } from 'src/components/ui/Icons/NewsletterIcon'
import { useNewsletter } from 'src/sdk/newsletter/useNewsletter'
import { LoadingGif } from 'src/components/ui/Icons'
import axios from 'axios'

export interface NewsletterProps
  extends Omit<ComponentPropsWithRef<'form'>, 'title'> {
  title: ReactNode
  subtitle?: ReactNode
}

interface Status {
  message?: string
  success: boolean
  className: string
}

const Newsletter = forwardRef<HTMLFormElement, NewsletterProps>(
  function Newsletter({ title, subtitle, onSubmit, ...otherProps }, ref) {
    const emailInputRef = useRef<HTMLInputElement>(null)

    const [status, setStatus] = useState<Status>({
      message: undefined,
      success: false,
      className: '',
    })

    const validEmail = (email: string) => {
      const validation =
        /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z]){2,}([.]){1}([a-z]){2,}([.]?){1}([a-z]?){2,}$/i

      return validation.test(email)
    }

    const { loading } = useNewsletter()

    const handleSubmit = async (event: FormEvent, email: string) => {
      event.preventDefault()

      const formElement = event.currentTarget as HTMLFormElement

      if (!validEmail(email)) {
        setStatus({
          message: 'Dirección de correo electrónico inválida',
          success: false,
          className: 'newsletter-status-error',
        })

        formElement.reset()

        return
      }

      try {
        await axios.patch('/api/subscribeNewsletter', {
          email,
        })

        setStatus({
          message: '¡Gracias por suscribirte!',
          success: true,
          className: 'newsletter-status-success',
        })

        formElement.reset()
      } catch (error) {
        if (error.response.status === 304) {
          setStatus({
            message: '¡Ya estás suscribirte!',
            success: true,
            className: 'newsletter-status-already',
          })
          formElement.reset()
        }

        if (error.response.status === 500) {
          setStatus({
            message: `Tu correo electrónico ya está registrado.`,
            success: false,
            className: 'newsletter-status-error',
          })
          formElement.reset()
        } else {
          setStatus({
            message: `Error Interno`,
            success: false,
            className: 'newsletter-status-error',
          })
          formElement.reset()
        }
      }
    }

    return (
      <section data-store-newsletter>
        <Form
          data-newsletter-form
          ref={ref}
          onSubmit={(event) =>
            handleSubmit(event, emailInputRef.current?.value ?? '')
          }
          {...otherProps}
        >
          <div className="data-newsletter-icon-container">
            <NewsletterIcon />
          </div>
          <section data-newsletter-section>
            <div data-newsletter-header>
              <h2 data-newsletter-title>{title}</h2>
            </div>

            <div data-newsletter-wrapper>
              <Label htmlFor="newsletter-email">{subtitle}</Label>
              <div data-newsletter-controls>
                <Input
                  id="newsletter-email"
                  name="newsletter-email"
                  placeholder="nombre@correo.com"
                  ref={emailInputRef}
                />

                <div data-newsletter-button>
                  <LoadingButton type="submit" loading={loading}>
                    Suscribirme
                  </LoadingButton>
                  {loading && <LoadingGif />}
                </div>
              </div>
              {status.message && (
                <Label className={status.className}>{status.message}</Label>
              )}
            </div>
            <Link href="/lp/politica-de-privacidad/">
              Política de privacidad
            </Link>
          </section>
        </Form>
      </section>
    )
  }
)

export default Newsletter
